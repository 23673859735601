<template>
  <v-container fluid>
    <v-layout wrap>
      <v-col cols="12" sm="12" md="12">
        <h2>{{$t('actions.filtrar').toUpperCase()}}</h2>
        <v-layout wrap>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.desde')}}</label>
            <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  clearable
                  v-model="search.inicio"
                  :color="$primary"
                  prepend-icon="event"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :color="$primary"
                :max="search.fim"
                v-model="search.inicio"
                locale="pt-PT"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.ate')}}</label>
            <v-menu
              ref="menu"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  clearable
                  v-model="search.fim"
                  :color="$primary"
                  prepend-icon="event"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :color="$primary"
                v-model="search.fim"
                :min="search.inicio"
                locale="pt-PT"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.prioridade')}}</label>
            <v-autocomplete
              clearable
              autocomplete="off"
              :items="getPrioridades"
              :color="$primary"
              v-model="search.prioridade"
              item-text="nome"
              item-value="id_prioridade"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="3" md="3"></v-col>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.cliente')}}</label>
            <v-autocomplete
              clearable
              autocomplete="off"
              :items="getClientes"
              :color="$primary"
              v-model="search.cliente"
              item-text="nome"
              item-value="nome"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.tipo_at')}}</label>
            <v-autocomplete
              clearable
              autocomplete="off"
              :items="getTipos"
              :color="$primary"
              v-model="search.tipo_at"
              item-text="nome"
              item-value="id_tipo_at"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="3" md="3">
            <label class="label-modal">{{$t('fields.state')}}</label>
            <v-autocomplete
              clearable
              autocomplete="off"
              :items="getStates"
              :color="$primary"
              v-model="search.estado"
              item-text="nome"
              item-value="estado"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4" sm="3" md="3"></v-col>
          <v-col cols="12" sm="9" md="9" class="d-flex align-center pr-0">
            <DCL :MyObject="search"/>
          </v-col>
          <v-col cols="4" sm="3" md="3" class="d-flex align-center">
            <v-btn class="ml-3" large dark @click="filter()" :color="$primary">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
            <v-btn
              text
              class="ml-3"
              large
              dark
              @click="clear()"
              :color="$primary"
            >{{$t('actions.limpar')}}</v-btn>
          </v-col>
        </v-layout>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <Datatable
          v-if="$_HASPERMISSION(NAME, getSession.user.id_tipo_utilizador)"
          :TableItems="IsFilter ? TMP : TableItems"
          :TableHeaders="TableHeaders"
          :FormFields="FormFields"
          :TableActions="TableActions"
          :ModalNewText="$t(`datatables.${NAME}.ModalNewText`)"
          :ModalUpdateText="$t(`datatables.${NAME}.ModalUpdateText`)"
          :ModalDeleteText="$t(`datatables.${NAME}.ModalDeleteText`)"
          :DeleteDialogTitle="$t(`datatables.${NAME}.DeleteDialogTitle`)"
          :TableTitle="$t(`datatables.${NAME}.TableTitle`)"
          :Store="NAME"
        />
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import { _MODELS } from "@/store/_MAIN"
import STATES from "@/mixins/_STATES"
const _NAME = "AT"
export default {
  mixins: [STATES],
  components: {
    Datatable: () => import("@/components/Datatable"),
    DCL: () => import("@/components/selects/DCL"),
  },
  computed: {
    getSession() {
      return this.$store.getters["session/getState"]
    },
    getTipos() {
      return this.$store.getters["tipo_at/getAll"]
    },
    getStates() {
      return this.STATES.at
    },
    getClientes() {
      return this.$store.getters["at/getClients"]
    },
    getPrioridades() {
      return this.$store.getters["prioridade/getAll"]
    },
    TableItems() {
      return this.$store.getters[`${this.NAME}/getAll`].map(el => {
        el.tipo_at = this.$store.getters["tipo_at/getById"](el.id_tipo_at)
        el.data_maximo = this.$moment(el.created_at)
          .add(
            this.$store.getters["prioridade/getDias"](el.id_prioridade),
            "days"
          )
          .format("YYYY-MM-DD HH:mm:ss")

        el.data_agendamento = !el.data ? "Sem data" : el.data + " " + el.hora
        el.prioridade = this.$store.getters["prioridade/getById"](
          el.id_prioridade
        )
        if (el.codigo) el.codigo = Number(el.codigo);
        return el
      })
    },
  },
  data: () => ({
    TableActions: _MODELS[_NAME]._ACTIONS,
    FormFields: _MODELS[_NAME]._FIELDS,
    TableHeaders: _MODELS[_NAME]._HEADERS,
    NAME: _NAME.toLowerCase(),
    menu1: false,
    menu2: false,
    search: {},
    IsFilter: false,
    TMP: [],
  }),
  methods: {
    async filter() {
      var array = [].concat(this.TableItems);

      if (this.search.inicio) array = await this.filterInicio(array)
      if (this.search.fim) array = await this.filterFim(array)
      if (this.search.prioridade) array = await this.filterPrioridade(array)
      if (this.search.cliente) array = await this.filterCliente(array)
      if (this.search.tipo_at) array = await this.filterAssistencia(array)
      if (this.search.estado) array = await this.filterEstado(array)
      if (this.search.id_distrito > 0) array = await this.filterDistrito(array)
      if (this.search.id_concelho > 0) array = await this.filterConcelho(array)
      if (this.search.id_localidade > 0) array = await this.filterLocalidade(array)

      this.TMP = [].concat(array)
      this.IsFilter = true
    },
    filterInicio(array) {
      return array.filter(el => {
        return (
          !isNaN(new Date(el.data_agendamento).getTime()) &&
          new Date(el.data_agendamento) <= new Date(this.search.inicio)
        )
      })
    },
    filterFim(array) {
      return array.filter(el => {
        return (
          !isNaN(new Date(el.data_agendamento).getTime()) &&
          new Date(el.data_agendamento) <= new Date(this.search.fim)
        )
      })
    },
    filterPrioridade(array) {
      return array.filter(el => {
        return Number(el.id_prioridade) === Number(this.search.prioridade)
      })
    },
    filterCliente(array) {
      return array.filter(el => {
        return (
          el.nome.includes(this.search.cliente)
        )
      })
    },
    filterAssistencia(array) {
      return array.filter(el => {
        return Number(el.id_tipo_at) === Number(this.search.tipo_at)
      })
    },
    filterEstado(array) {
      return array.filter(el => {
        return Number(el.estado) === Number(this.search.estado)
      })
    },
    filterDistrito(array) {
      return array.filter(el => {
        return Number(el.id_distrito) === Number(this.search.id_distrito)
      })
    },
    filterConcelho(array) {
      return array.filter(el => {
        return Number(el.id_concelho) === Number(this.search.id_concelho)
      })
    },
    filterLocalidade(array) {
      return array.filter(el => {
        return Number(el.id_localidade) === Number(this.search.id_localidade)
      })
    },
    clear() {
      this.IsFilter = false
      this.search = {}
    }
  }
}
</script>
<style scoped>
.v-select,
.col-3,
.v-input {
  padding: 0 10px 0 0 !important;
}
</style>